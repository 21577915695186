import React, {useCallback, useEffect, useMemo, useRef, useState,} from 'react';
import {addNewRow, createHeaders,} from '../functions/functions';
import SummaryRow from './sub-parts/SummaryRow';
import TableHeaders from './sub-parts/TableHeaders';
import TableContent from "./TableContent";

const FormTable = (
    {
        columns,
        formData,
        formErrors,
        setFormData,
        onFormValueChange,
    }
) => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [gridWidths, setGridWidths] = useState([]);
    const tableElement = useRef(null);

    const memoColumnsList = useMemo(() => {
        return createHeaders(columns);
    }, [columns]);

    let columnsList = memoColumnsList.map((column) => {
        return {
            ...column,
            ref: useRef(),
        };
    });

    const mouseDown = (index) => {
        setActiveIndex(index);
    };

    const mouseMove = useCallback(
        (e) => {
            const gridColumns = columnsList.map((col, i) => {

                let minWidth = parseInt(col.minWidth);

                if (i === activeIndex) {
                    const width =
                        e.clientX -
                        col.ref.current.offsetLeft -
                        81 +
                        tableElement.current.scrollLeft;
                    if (width >= minWidth) {
                        return `${width}px`;
                    }

                    return `${minWidth}px`;
                }
                return `${col.ref.current.offsetWidth}px`;
            });

            setGridWidths(gridColumns);
            tableElement.current.style.gridTemplateColumns = `${gridColumns.join(' ')}`;
        },
        [activeIndex, columnsList]
    );

    const removeListeners = useCallback(() => {
        window.removeEventListener('mousemove', mouseMove);
        window.removeEventListener('mouseup', removeListeners);
    }, [mouseMove]);

    const mouseUp = useCallback(() => {
        setActiveIndex(null);
        removeListeners();
    }, [setActiveIndex, removeListeners]);

    useEffect(() => {
        if (activeIndex !== null) {
            window.addEventListener('mousemove', mouseMove);
            window.addEventListener('mouseup', mouseUp);
        }

        return () => {
            removeListeners();
        };
    }, [activeIndex, mouseMove, mouseUp, removeListeners]);

    useEffect(() => {

        const gridColumns = columnsList.map((col, i) => {
            return col.width ?? col.minWidth;
        });

        setGridWidths(gridColumns);
        tableElement.current.style.gridTemplateColumns = `${gridColumns.join(' ')}`;

    }, []);

    const b2bGroupColumn = columns.find(e => (e.property === 'b2bGroup'));

    return (
        <div className="table-container">
            <div className="table-wrapper">
                <table className="resizeable-table" ref={tableElement}>
                    <TableHeaders
                        columnsList={columnsList}
                        activeIndex={activeIndex}
                        gridWidths={gridWidths}
                        mouseDown={mouseDown}
                    />
                    {/* table content */}
                    {columnsList?.length > 0 && formData?.length > 0 && <TableContent
                        columnsList={columnsList}
                        formData={formData}
                        formErrors={formErrors}
                        setFormData={setFormData}
                        onFormValueChange={onFormValueChange}
                        gridWidths={gridWidths}
                    />}
                    {/* new row add button */}
                    <tbody>
                    <tr>
                        <td style={{gridColumn: 'span ' + columnsList?.length}}>
                            {(b2bGroupColumn?.choices ?? []).map((b2bGroup) => {

                                return <button
                                    className="btn btn-success btn-sm "
                                    style={{width: 'auto', padding: '5px 8px', margin: '6px 0 6px 6px'}}
                                    onClick={() => {
                                        setFormData((formData) => {

                                            let newRows = [];

                                            for (let i = 0; i < b2bGroup.maxPerRoom; i++) {
                                                newRows.push(addNewRow(formData, b2bGroup, columns));
                                            }

                                            return [...formData, ...newRows];
                                        })
                                    }}
                                >
                                    <span className="fa fa-plus"></span> {b2bGroup.label}
                                </button>;
                            })}
                        </td>
                    </tr>
                    {/* summary row */}
                    <SummaryRow
                        columnsList={columnsList}
                        formData={formData}
                        gridWidths={gridWidths}
                    />
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default FormTable;
